import { CUSTOMER_TYPES } from "@/core/constant";

export const INIT_CUSTOMER_VALUE = {
  alias: null,
  avatar: null,
  addresses: [
    {
      tinh_thanhpho: null,
      quan_huyen: null,
      phuong_xa: null,
      so_nha: null
    }
  ],
  name: null,
  email: null,
  phone: null,
  dob: null,
  gender: null,
  job: null,
  company: null,
  facebook: null,
  cmnd: null,
  note: null,
  birthType: "FULL",
  customer_type: CUSTOMER_TYPES.CA_NHAN.value
};

export const INIT_CUSTOMER_ADDRESS_VALUE = {
  of_user_id: null,
  name: null,
  phone: null,
  tinh_thanhpho: null,
  quan_huyen: null,
  phuong_xa: null,
  so_nha: null
};
