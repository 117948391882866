import Api from "@/core/apis";

export default {
  getCollections(data) {
    return Api().post("v2/collection", data);
  },

  updateCollection(data) {
    return Api().post("v2/collection/update/", data);
  }
};
