/**
 * Rule in store modules
 * State: use noun, not use verb
 * Getters: use name in state (if getter use filter or handle other, use bellow: filter + name in state ~ filterUser)
 * Mutations: user bellow: set + name in state ~ SET_USER
 * Actions: use verb, not use noun. As bellow: get + name in state ~ getUser .Sometimes, If actions don't use to get, u must have use verb not use noun.
 */

import MediaService from "@/core/service/media.service";
import ProductService from "../service/product.service";
import store from "@/core/store";
import Vue from "vue";
import { catchError } from "@/core/composables";
import { INIT_PRODUCT_VALUE } from "@/modules/Goods/constants";

const state = {
  openAiCompletionsResult: {},
  paginationInfo: {
    from: 0,
    to: 0,
    pageTotal: 1,
    itemTotal: 0
  },
  product: JSON.parse(JSON.stringify(INIT_PRODUCT_VALUE)),
  productModelSiteConfigs: [],
  products: [],
  searchProducts: [],
  statusRequest: {
    message: null,
    value: null
  },
  uploadedImages: [],
  uploadedThumbnail: null
};

const getters = {
  openAiCompletionsResult: state => state.openAiCompletionsResult,
  paginationInfo: state => state.paginationInfo,
  product: state => state.product,
  productModelSiteConfigs: state => state.productModelSiteConfigs,
  products: state => state.products,
  searchProducts: state => state.searchProducts,
  statusRequest: state => state.statusRequest,
  uploadedImages: state => state.uploadedImages,
  uploadedThumbnail: state => state.uploadedThumbnail
};

const mutations = {
  SET_OPENAI_COMPLETIONS_RESULT: (state, payload) => {
    state.openAiCompletionsResult = payload;
  },

  RESET_STATUS_REQUEST: state => {
    state.statusRequest.message = null;
    state.statusRequest.value = null;
  },

  SET_PAGINATION_INFO: (state, payload) => {
    state.paginationInfo = payload;
  },

  SET_PRODUCT: (state, payload) => {
    state.product = payload;
  },

  SET_PRODUCT_MODEL_SITE_CONFIGS: (state, payload) => {
    state.productModelSiteConfigs = payload;
  },

  SET_PRODUCTS: (state, payload) => {
    state.products = payload;
  },

  SET_SEARCH_PRODUCTS: (state, payload) => {
    state.searchProducts = payload;
  },

  SET_STATUS_REQUEST: (state, payload) => {
    state.statusRequest.message = payload.message || null;
    state.statusRequest.value = payload.value;
  },

  SET_UPLOADED_IMAGES: (state, payload) => {
    state.uploadedImages = payload;
  },

  SET_UPLOADED_THUMBNAIL: (state, payload) => {
    state.uploadedThumbnail = payload;
  }
};

const actions = {
  createProduct: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-createProduct" });

      const response = await ProductService.createProduct(payload.data);

      if (response.status === 200 && response.data) {
        Vue.set(state.product, "id", response.data);
        // Get products
        if (payload.routeQuery) {
          await store.dispatch("PRODUCT/getProducts", payload.routeQuery);
        }
        commit("SET_STATUS_REQUEST", { value: "success-createProduct" });
      }
    } catch (e) {
      catchError(e, commit, "createProduct");
    }
  },

  deleteProducts: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-deleteProducts" });

      const response = await ProductService.deleteProducts(payload.data);

      if (response.status === 200) {
        // Get products
        if (payload.routeQuery) {
          await store.dispatch("PRODUCT/getProducts", payload.routeQuery);
        }
        commit("SET_STATUS_REQUEST", { value: "success-deleteProducts" });
      }
    } catch (e) {
      catchError(e, commit, "deleteProducts");
    }
  },

  editSpecs: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-editSpecs" });

      const response = await ProductService.editSpecs(payload);

      if (response.status === 200) {
        commit("SET_STATUS_REQUEST", {
          value: "success-editSpecs"
        });
      }
    } catch (e) {
      catchError(e, commit, "editSpecs");
    }
  },

  getAllProducts: async ({ commit }) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getAllProducts" });

      const response = await ProductService.getAllProducts();

      if (response.status === 200 && response.data.data) {
        commit("SET_PRODUCTS", response.data.data);
        commit("SET_STATUS_REQUEST", { value: "success-getAllProducts" });
      }
    } catch (e) {
      catchError(e, commit, "getAllProducts");
    }
  },

  getProductById: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getProductById" });

      const response = await ProductService.getProductById(payload);

      const { data } = response.data;

      if (response.status === 200 && data) {
        await commit("SET_PRODUCT", data);

        Vue.set(state.product, "id", payload);

        commit("SET_STATUS_REQUEST", { value: "success-getProductById" });
      }
    } catch (e) {
      catchError(e, commit, "getProductById");
    }
  },

  getProducts: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-getProducts" });

      const response = await ProductService.getProducts(payload);

      if (response.status === 200 && response.data.data) {
        commit("SET_PRODUCTS", response.data.data);
        commit("SET_PAGINATION_INFO", {
          from: response.data.meta.from ? response.data.meta.from : 0,
          to: response.data.meta.to ? response.data.meta.to : 0,
          pageTotal: response.data.meta.last_page,
          itemTotal: response.data.meta.total
        });
        commit("SET_STATUS_REQUEST", { value: "success-getProducts" });
      }
    } catch (e) {
      catchError(e, commit, "getProducts");
    }
  },

  importExcel: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-importExcel" });

      const response = await ProductService.importExcel(payload.formData);

      if (response.status === 200 && response.data) {
        // Get products
        await store.dispatch("PRODUCT/getProducts", payload.routeQuery);
        commit("SET_STATUS_REQUEST", { value: "success-importExcel" });
      }
    } catch (e) {
      catchError(e, commit, "importExcel");
    }
  },

  openAiCompletions: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-openAiCompletions" });

      const response = await ProductService.openAiCompletions(payload);

      if (response.status === 200 && response.data) {
        commit("SET_OPENAI_COMPLETIONS_RESULT", response.data);
        commit("SET_STATUS_REQUEST", { value: "success-openAiCompletions" });
        return response.data;
      } else {
        commit("SET_STATUS_REQUEST", { value: "error-openAiCompletions" });
      }
    } catch (e) {
      catchError(e, commit, "getProducts");
    }
  },

  resetProduct: ({ commit }) => {
    commit("SET_PRODUCT", JSON.parse(JSON.stringify(INIT_PRODUCT_VALUE)));
  },

  searchProducts: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-searchProducts" });
      const response = await ProductService.searchProducts(payload);

      if (response.status === 200 && response.data.data) {
        commit("SET_SEARCH_PRODUCTS", response.data.data);
        commit("SET_STATUS_REQUEST", { value: "success-searchProducts" });
      }
    } catch (e) {
      catchError(e, commit, "searchProducts");
    }
  },

  setProductModelSiteConfigs: ({ commit }, payload) => {
    commit("SET_PRODUCT_MODEL_SITE_CONFIGS", payload);
  },

  updateProduct: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-updateProduct" });

      const response = await ProductService.updateProduct(payload.data);

      if (response.status === 200) {
        // Get products
        if (payload.routeQuery) {
          await store.dispatch("PRODUCT/getProducts", payload.routeQuery);
        }
        commit("SET_STATUS_REQUEST", { value: "success-updateProduct" });
      }
    } catch (e) {
      catchError(e, commit, "updateProduct");
    }
  },

  uploadImages: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", {
        value: "loading-uploadImages"
      });

      // Append folder
      payload.formData.append("folder", "core/products");
      payload.formData.append("name", payload.name);
      payload.formData.append("sites", payload.sites);

      const response = await MediaService.uploadMultipleImages(
        payload.formData
      );
      if (response.status === 200) {
        await commit("SET_UPLOADED_IMAGES", response.data);

        commit("SET_STATUS_REQUEST", {
          value: "success-uploadImages"
        });
      }
    } catch (e) {
      catchError(e, commit, "uploadImages");
    }
  },

  uploadThumbnail: async ({ commit }, payload) => {
    try {
      commit("SET_STATUS_REQUEST", { value: "loading-uploadThumbnail" });

      // Append folder
      payload.formData.append("folder", "core/products");
      payload.formData.append("name", payload.name);
      payload.formData.append("sites", payload.sites);

      const response = await MediaService.uploadImage(payload.formData);

      if (response.status === 200) {
        await commit("SET_UPLOADED_THUMBNAIL", response.data);
        commit("SET_STATUS_REQUEST", { value: "success-uploadThumbnail" });
      }
    } catch (e) {
      catchError(e, commit, "uploadThumbnail");
    }
  },

  addSpecs: async ({ commit }, payload) => {
    try {
      const { data } = await ProductService.addProductSpecs(payload);

      return data;
    } catch (e) {
      catchError(e, commit, "addSpecs");
    }
  },

  deleteSpecs: async ({ commit, dispatch, state }, payload) => {
    try {
      const { data } = await ProductService.deleteSpecs(payload);

      await dispatch("getProductById", state.product.id);

      return data;
    } catch (e) {
      catchError(e, commit, "deleteSpecs");
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
