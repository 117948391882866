export const CUSTOMER_TYPES = {
  CA_NHAN: {
    label: "Cá nhân",
    value: "ca_nhan"
  },
  DOANH_NGHIEP: {
    label: "Doanh nghiệp",
    value: "doanh_nghiep"
  }
};

export const BUSINESS_INDUSTRIES = [
  "Bán lẻ trực tuyến",
  "Cửa hàng tiện lợi",
  "Siêu thị & đại lý",
  "Xuất nhập khẩu",
  "Thực phẩm sạch",
  "Dịch vụ tài chính",
  "Dịch vụ pháp lý",
  "Quảng cáo & tiếp thị",
  "Logistics & vận tải",
  "Y tế & chăm sóc sức khỏe",
  "Phát triển phần mềm",
  "Bảo mật & an ninh mạng",
  "AI & dữ liệu lớn",
  "Điện toán đám mây",
  "Sản xuất hàng tiêu dùng",
  "Chế biến thực phẩm",
  "Gia công cơ khí",
  "Xây dựng & vật liệu",
  "Trung tâm ngoại ngữ",
  "Gia sư trực tuyến",
  "Đào tạo kỹ năng mềm"
];

export const EMPLOYEE_SCALE = [
  "1 - 10 nhân sự",
  "11 - 50 nhân sự",
  "51 - 200 nhân sự",
  "201 - 500 nhân sự",
  "501 - 1000 nhân sự",
  "1000+ nhân sự"
];
