import CollectionService from "../service/collection.service";

const state = {
  collections: [],
  statusRequest: {
    message: null,
    value: null
  }
};

const getters = {
  collections: state => state.collections,
  statusRequest: state => state.statusRequest
};

const mutations = {
  SET_COLLECTIONS(state, collections) {
    state.collections = collections;
  },

  SET_STATUS_REQUEST(state, statusRequest) {
    state.statusRequest = statusRequest.value;
  }
};

const actions = {
  async getCollections({ commit }, data) {
    try {
      commit("SET_STATUS_REQUEST", {
        message: null,
        value: "loading-getCollections"
      });
      const response = await CollectionService.getCollections(data);
      commit("SET_COLLECTIONS", response.data.data);
      commit("SET_STATUS_REQUEST", {
        message: response.message,
        value: "success-getCollections"
      });
    } catch (error) {
      console.error(error);
      commit("SET_STATUS_REQUEST", {
        message: error.message,
        value: "error-getCollections"
      });
    }
  },

  async updateCollection({ commit }, data) {
    try {
      commit("SET_STATUS_REQUEST", {
        message: null,
        value: "loading-updateCollection"
      });
      const response = await CollectionService.updateCollection(data);

      commit("SET_COLLECTIONS", response.data.data);
      commit("SET_STATUS_REQUEST", {
        message: response.message,
        value: "success-updateCollection"
      });
    } catch (error) {
      console.error(error);
      commit("SET_STATUS_REQUEST", {
        message: error.message,
        value: "error-updateCollection"
      });
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
