import Api from "@/core/apis";

export default {
  addInvoiceParticipant(data) {
    return Api().post("invoice-participant", data);
  },

  exportExcel(payload) {
    return Api().post("export-report/orders", payload, {
      responseType: "blob"
    });
  },

  cancelOrder(id, cancelReason) {
    return Api().put(`orders/cancel/${id}`, {
      canceled_reasons: cancelReason
    });
  },

  cancelOrderProcessing(id, cancelReason) {
    return Api().put(`orders/handles/cancel/${id}`, {
      canceled_reasons: cancelReason
    });
  },

  checkPromotionCode(orderId, code) {
    return Api().post("orders/check-code", {
      order_id: orderId,
      code: code
    });
  },

  completeOrder({ id, status }) {
    return Api().post(`orders/finish`, { id: id, status: status });
  },

  createOrder(data) {
    return Api().post("orders", data);
  },

  createOrderProcessing(data) {
    return Api().post("orders/handles", data);
  },

  editInvoiceParticipant(data) {
    return Api().put("invoice-participant", data);
  },

  exportReportFile(data) {
    return Api().post("export-report/orders", data, { responseType: "blob" });
  },

  getOrderById(id) {
    return Api().get(`orders/${id}`);
  },

  getOrderOnlinePayments(orderId) {
    return Api().get(`orders/online-payments/${orderId}`);
  },

  getOrderPaymentTransactions(orderId) {
    return Api().get(`orders/${orderId}/transactions`);
  },

  getOrderProcessingById(id) {
    return Api().get(`orders/handles/${id}`);
  },

  getOrders(data) {
    return Api().post("orders/list", data);
  },

  updateOrder(data) {
    return Api().put("orders", data);
  },

  updateOrderProcessing(data) {
    return Api().put("orders/handles", data);
  },

  updateOrderStatus(orderId, status) {
    return Api().put("orders", {
      id: orderId,
      status: status
    });
  },

  uploadImage(data) {
    return Api().post("images/voucher/upload", data);
  },

  exportInvoice(payload) {
    return Api().post("orders/handles/exportGoods", payload);
  },

  exportRepairInvoice(payload) {
    return Api().post("exportGoods/add", payload);
  },

  getDepositTransactions(id) {
    return Api().get(`orders/${id}/deposit-transactions`);
  },

  getDeliveryTime(params) {
    return Api().$get(`orders/handle/shipping/delivery-time`, { params });
  },

  updateShippingInfo(params) {
    return Api().$post("orders/handle/shipping/update", params);
  },

  getShippingFee(params) {
    return Api().$post("/orders/handle/shipping/fee", params);
  },

  getMessageTelegram(params) {
    return Api().$post("/orders/handles/messageTelegram", params);
  },

  sendMessageTelegram(params) {
    return Api().$post("/orders/handles/sentMessageTelegram", params);
  },

  updateFeedbackImages(data) {
    return Api().$post("/orders/update-feedback", data);
  }
};
