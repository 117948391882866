import Api from "@/core/apis";

export default {
  getCities() {
    return Api().get("region");
  },

  getDistrictsOfCity(provinceCode) {
    return Api().get("region", {
      params: {
        province_code: provinceCode
      }
    });
  },

  getWardsOfDistrict(districtCode) {
    return Api().get("region", {
      params: {
        district_code: districtCode
      }
    });
  }
};
